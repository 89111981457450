<template>
  <baseTemplate>
    <div class="search-box">
      <van-search v-model="searchVal" :clearable="false" :placeholder="$t('baseInfo.bankName')" @input="handleSearch" />
    </div>
    <div class="content">
      <div v-show="searchVal" class="list-box">
        <van-radio-group v-model="searchCheckVal" @change="changeHandle">
          <van-radio v-for="(item,index) in searchDataList" :key="index" :name="item.bank_name">
            <template #icon="props">
              <li :class="{'is-active':props.checked}" class="list-item" v-html="item.bankName" />
            </template>
          </van-radio>
        </van-radio-group>
      </div>
      <div v-show="!searchVal" class="list-box-bar">
        <index-bar-item ref="indexBar" :data-list="bankList" @changeHandle="changeHandle" />
      </div>
    </div>
  </basetemplate>
</template>

<script>
import IndexBarItem from './indexBarItem'
export default {
  components: { IndexBarItem },
  data() {
    return {
      searchVal: '',
      searchCheckVal: '',
      searchDataList: []
    }
  },
  computed: {
    bankList: {
      get() {
        return this.$store.state.settings.bankList
      }
    },
    checkBank: {
      get() {
        return this.$store.state.settings.checkBank
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'checkBank',
          value: val
        })
      }
    }
  },

  methods: {
    changeHandle(val) {
      if (!val) return
      const item = this.bankList.find(item => { return item.bank_name === val })
      this.checkBank = item.bank_name
      history.back(-1)
    },

    // 搜索
    handleSearch(val) {
      if (!val) return
      const filterList = this.bankList.filter(item => {
        item.bankName = item.bank_name.replace(val, `<span style="color:red;">${val}</span>`)
        return item.bank_name.indexOf(this.searchVal) > -1
      })
      if (filterList.length) {
        this.searchDataList = JSON.parse(JSON.stringify(filterList)) || []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  padding: 24px 0 0 0;
}
:deep(.van-radio){
  display: initial;
}
:deep(.van-radio__icon){
  height: auto;
  padding: 0;
}

.search-box{
  padding: 0 16px;
}

:deep(.search-box .van-search){
  // margin-top: 40px;
  margin-bottom: 20px;
  height: 148px;
  //background: #fff;
  // border-radius: 20px;
  // box-shadow: 0px 0px 32px 0px #0000004f;
  background: url('~@/assets/images/baseInfo/bank-search-icon.png') no-repeat center center;
  background-size: 100%;
}
:deep(.search-box .van-search .van-search__content),
:deep(.search-box .van-field__body){
  height: 100%;
  background: none;
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  input{
    color: #000;
    margin-top: -8px;
  }
}

:deep(.search-box .van-field__left-icon .van-icon-search){
  color: #000;
  font-size: 40px!important;
  width: 48px;
  height: 48px;
  background: url('~@/assets/images/baseInfo/search-icon.png') no-repeat center center;
  background-size: 100%;
  margin-right: 18px;
  margin-top: 28px;
  margin-left: 30px;
  &::before{
    display: none;
  }
}

.content{
  height: calc(100% - 128px);
  overflow: auto;
  padding-bottom: 10px;
  .list-item{
    height: 90px;
    line-height: 90px;
    background: #FFFFFF;
    border-radius: 24px;
    margin-bottom: 20px;
    font-size: 28px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: rgba(0,0,0,0.8);
    position: relative;
    padding-left: 50px;
  }
  .van-radio:last-child .list-item{
    border: none;
  }
  .list-item.is-active{
    // color: #0BB8E4FF;
    background: #E6E6E7;
  }
  .list-item.is-active::after{
    display: none;
    content:'';
    position:absolute;
    width: 32px;
    height: 32px;
    background: url(~@/assets/images/public/selected-icon.png) no-repeat center center;
    background-size: 100%;
    right: 32px;
    top: 50%;
    margin-top: -16px;
  }
}
:deep(.van-index-bar__sidebar){
  display: none;
}
</style>
