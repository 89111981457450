<template>
  <div>
    <van-index-bar :sticky="false">
      <van-radio-group v-model="emitVal" @change="changeHandle">
        <van-index-anchor v-for="(item,index) in codeObj" :key="index" :index="index">
          <div v-show="codeObj[index].length" class="index-box">{{ index }}</div>
          <van-radio v-for="(items,i) in codeObj[index]" :key="i" :name="items.bank_name">
            <template #icon="props">
              <van-cell class="list-item" :class="{'is-active':props.checked}" :title="items.bank_name" />
            </template>
          </van-radio>
        </van-index-anchor>
      </van-radio-group>
    </van-index-bar>
  </div>
</template>

<script>
export default {
  name: 'IndexBarItem',
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      codeArry: [...Array(26).keys()].map(i => String.fromCharCode(i + 65)), // 26字母
      codeObj: {},
      emitVal: ''
    }
  },
  watch: {
    dataList: {
      handler() {
        this.setObjData()
      },
      immediate: true
    }
  },
  methods: {
    setObjData() {
      this.codeArry.forEach(item => {
        this.codeObj[item] = []
        const findData = this.dataList.filter(items => { return items.bank_name.indexOf(item) + '' === '0' })
        if (findData && findData.length) this.codeObj[item].push(...findData)
      })
    },
    changeHandle() {
      this.$emit('changeHandle', this.emitVal)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.van-index-bar__sidebar){
  top:60%;
}
:deep(.van-index-bar__index){
  line-height: 35px;
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #333333;
  padding-right: 20px;
}

:deep(.parent-index){
  height: 48px;
  background: #F0F0F0;
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #333333;
}

:deep(.van-cell__title){
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #333333;
}
:deep(.van-index-anchor),
:deep(.van-cell){
  padding: 0;
}
.index-box{
  background: #00000005;
  padding-left: 60px;
  color: #383844;
  line-height: 42px;
  margin-bottom: 20px;
  font-size: 26px;
  color: #CCCCCC;
}
:deep(.list-box){
  left: 0;
  right: 0;
}
.list-item{
  height: 50px;
  line-height: 50px;
  background: #FFFFFF;
  margin-bottom: 32px;
  padding-left: 60px;
  position: relative;
  font-size: 30px;
  color: #000000;
}

:deep(.van-index-anchor){
  background: none;
}

:deep(.van-radio__icon){
  padding: 0 32px;
}

:deep(.list-item.is-active){
  // color: #0BB8E4FF;
  background: #E6E6E7;
}
:deep(.is-active::before){
  display: none;
  content:'';
  position:absolute;
  width: 32px;
  height: 32px;
  background: url(~@/assets/images/public/selected-icon.png) no-repeat center center;
  background-size: 100%;
  right: 32px;
  top: 50%;
  margin-top: -16px;
}
</style>
